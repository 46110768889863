@import "sass-lib";

@media screen and(min-width:1px) and(max-width:1023px){
	.btn { font-size: 13px; }
	div.big-text-bg {
		img { max-height: 66px; }
	}
	div.download-list-container {
		.download-list {
			margin-bottom: 20px;
		    p { float: none; position: relative; display: block; width: 100%; max-width: 100%; margin: 0px 0px 10px; }
		    a { float: none; }
		}
	}
	div.clear-mobile { display: block; clear: both; width: 100%; }
	div.pagination { margin-top: 20px; 
		ul {
			li {
				a { font-size: 14px; }
			}
		}
	}
	div.downlist2-container {
		.downlist2 {
			.title-container {
				padding: 25px 0px 60px 0px; 
				a { font-size: 14px; line-height: 22px; }
			}
		}
	}
	p {
		&.text-title { top: 55px; font-size: 18px; 
			span.line { width: 40px; }
		}
	}
	form {
		div.box {
			select {
				z-index: 3; background-color: transparent;
				&.plain { font-size: 14px; padding: 0px 31px 0px 0px; }
			}
			input { padding: 3px 0px 8px 0px }
			&.select {
				span {
					&.arrow-select { top: 3px; z-index: 1; }
				}
			}
			span.title { font-size: 12px; }
		}
	}
	body {
		p { font-size: 14px; line-height: 22px; }
		div.slider-3 {
			margin-bottom: 0px;
			.list {
				position: relative; 
				.image { position: relative; top: 0px; width: 100%; float: none; padding-right: 0;
					img { margin: 0 auto; }
				}
				.content { 
					text-align: center;width: 100%; padding-top: 20px;
					&:before {left: 0; right: 0; margin: auto;}
					p.title { margin-bottom:0; }
					.spec-list {
						text-align: center;
						.list {float: none; display: inline-block;}
					}
				}
			}
		}
		div.mobile-wrapper-fluid {
			&.project {
				margin: 0px; padding: 0px;
			}
		}
		div.slider-3-thumb {
			width: 90%; margin-left: 0px; @include boxSizing(border-box); left: 0; right: 0; bottom: auto; top: 51%; margin: auto; margin-bottom: 30px;
		}
		div.rect-big {
			padding: 52px 0px 160px 0px; position: relative; background-size: 200% auto; margin-bottom: 360px;
			.content { position: relative; padding: 0px 52px; }
			.map { position: absolute; margin-left: 0px; width: 100%; height: 280px; bottom: -146px; padding: 0px 54px; }
			.abs-content-2 { position: relative; left: 0%; width: 100%; padding: 0px 50px 30px; @include boxSizing(border-box);  
				h3 { font-size: 22px; }
				p { color: $grey; }
			}
			.content-right { margin-left: 0px; position: absolute; width: 85%; left: 0; right: 0; margin: 0 auto;
				form { padding: 35px 44px; }
			}
		}
		div.rect-big-white {
			position:relative; background-image: none; border: 1px solid $grey; padding-top: 0px; padding-bottom: 179px; margin: 0px 0px 223px;
		    .abs-content { position: relative; top: auto; left: auto; width: 100%; transform: translateY(0%); padding: 54px 50px 0px; @include boxSizing(border-box); }
		    .content-right {
		    	position: absolute; margin-left: 0px; float: none; padding: 0px 50px; height: 280px; 
		    	img { width: 100%; }
		    }
		}

		div.box-form {
			span , select { font-size: 14px; }
		}
		div.downlist-container {
			.downlist {
				.content {
					a.title { font-size: 14px; line-height: 22px; }
				}
				.downlink-abs {
					a { text-transform: uppercase; }
				}
			}
		}
		div.list-gallery {
			padding: 0% 8%;
			.list {
				width: 50%;
				p { margin-bottom: 0px; }
				&:nth-child(n+4) { margin-top:0px; }
			}
		}
		table {
			tr {
				th , td { font-size: 14px; }
			}
		}
		&.home , &:not(.home) {
			.wrapper , .wrapper-side , .wrapper-2 { width: 85%; position: relative; }
			header {
				background-color: $white; position: fixed; @include transition(all 0s ease-in-out);
				.wrapper { position: relative; background-image: none; 
					.hamburger-container {
						display: block; position: absolute; top: 36px;right: 0px; width: 25px; height: 20px; background-color: $white; cursor: pointer;
						span.hamburger { display: block; width: 25px; height: 4px; background-color: $red; position: relative; top: 9px; 
							&::after , &::before { position: absolute; width: 25px; height: 4px; background-color: $red; content:""; }
							&::after { top: 9px; }
							&::before { top: -9px; }
						}
					}
				}
				.top { display: none; }
				.bottom {
					position: relative;
					div.wrapper { padding: 25px 0px; }
					.fl {
						img { height: 40px; top: 0px; }
					}
					.fr {
						background-color: $white; position: relative; top: 26px; width: 100%; display: none;
						.search-mobile-container { display: none; position: absolute; top: 0px; height: 85px; background-color: $red; left: -9%; right: -9%; z-index: 10;
							form { width: 80%; position: relative;
								input { @include appearance(none); border: none; background-color: transparent; border-bottom: 1px solid #cc8d8f; color: #cc8d8f; font-size: 16px; font-family: "Open Sans"; font-style: italic; padding: 7px 0px; width: 100%; margin-top: 22px; padding-right: 40px;  @include boxSizing(border-box); }
								button { @include appearance(none); background-color: transparent; position: absolute; top: 17px; right: 0px; padding: 10px; }
							}
							ul.lang-mobile {
								position: absolute; right: 0px; top: 0px; padding-top: 0px;
								li { float: left; padding: 0px; width: auto; margin-top: 33px;
									&::before { display:none; }
									a {
										color: $white;
										&::after { content:''; position: absolute; top: 0px; right: 0px; height: 100%; width: 1px; background-color: $white; }
									}
									&.active {
										a { font-weight: bold; top: -1px; }
									}
									&:last-child {
										a { padding-right: 0px; 
											&::after { display:none; }
										}
									}
								}
							}
						}
						ul {
							position: relative; padding-top: 85px;
							li { padding: 37px 0px; display: block; width: 100%; @include boxSizing(border-box); position: relative;
								&::before { content:''; bottom:0px; left: -9%; right: -9%; height: 1px; background-color: #f1f1f1; position: absolute; }
								&:hover {
									background-color: $white;
									ul { display: none; }
								}
								a { font-size: 18px; position: relative; padding: 0px 16px; }
								.ctrl-dropdown-header { z-index: 8; position: absolute; top: 39px; right: 0px; width: 9px; height: 16px; background: url('../img/material/arrow-slider-right.png'); @include transition(all .4s ease-in-out); 
									&.active { @include rotation(90deg); }
								}

								ul {
									background-color: #f1f1f1; position: relative; padding: 0px; margin-top: 0px; padding-left: 50px; left: -9%; right: -9%; width: 110%; bottom: -36px;
									li {
										padding: 16px 0px 0px 50px; border: none;
										a { font-size: 16px; color: $grey2; line-height: 24px; text-transform: uppercase; padding: 0px 0px !important;
											&:hover { color:$red; }
										}
										&:hover { background-color: #f1f1f1; }
										&:first-child { margin-top: 20px; }
										&:last-child { margin-bottom: 40px; }
									}
									/*&::before { content:''; position: absolute; top: 0px; bottom: 0px; left: -9%; right: -9%; background-color: #f1f1f1; }*/
									&::after { content:''; display: block; clear: both; }
								}
							}
						}
					}
				}
				&::before { position: absolute; top: 93px; width: 100%; height: 1px; background-color: $grey; content: ""; } 
			}
			section {
				&.content {
					div.wrapper , div.wrapper-side , div.wrapper-2 { width: 85%; position: relative; }
				}
			}
		}
		&.home {
			h6 { font-size: 16px; }
			h3 { font-size: 30px; }
			section {
				&.mainbanner {
					background-position: 42% !important;
					.inner {
						h1 { max-width: 64%;}
					}
				}
				&.about {
					margin-bottom: 167px;
					.wrapper { padding-top: 160px; }
					.image , .content { float: none; width: 100%; display: block; }
					.image {
						text-align: center;
						img { max-width: 329px; }
					}
					.content {
						p { margin-top: 69px; font-size: 16px; line-height: 26px; padding: 0px 20px 53px 20px; }
					}
				}
				&.project {
					.big-text-bg { top: 80px; z-index: 5; }
					p.text-title { top: 110px; }
					.bg-content { -webkit-clip-path : none; clip-path: none; width: 100%; height: 660px; overflow: hidden;
						img { max-width: 500%; }
					}
					.slider-project {
						padding-bottom: 40px;
						.list {
							padding: 0px 19px;
							div.title-desc {
								position: relative; max-width: 80%; margin-bottom: 66px;
								span.border { width: 100px; height: 6px; }
								h2 { font-size: 40px; line-height: 45px; max-width: 100%; width:100%; margin: 25px 0px; }
								p { font-size: 14px; width: 100%; max-width: 100%; }
							}
						}
					}
					.slider-project-nav {
						width: 45% !important;
						button {
							&.slick-next { left: auto !important; }
						}
					}
				}
				&.news {
					margin-bottom: 100px;
					.wrapper-side { width: 100%;
						.image {
							height: 240px; background-color: #000; overflow: hidden; position: relative;
							img { position: absolute; top: 0px; left: 0px; bottom: 0px; max-width: 500%; transform: translate(-40%, -20%); }
						}
						.content {
							h4 { font-size: 18px; line-height: 24px; }
							p.desc { margin: 28px 0px; font-size: 14px; line-height: 22px; height: 40px; overflow: hidden; }
						}
					}
					.news-list-container {
						padding-left: 0px;
						.news-list { width: 30%; position: relative; display: block; padding-right: 0px; margin-right: 3.33%;
							&::after { content:""; clear: both; display: block; }
							&:last-child { margin-right: 0px; }
							h4 { font-size: 14px; line-height: 22px; margin-bottom: 5px; max-width: 100%; }
						}
					}
					.view-all-container { margin-top: 60px; display: block; width: 100%; text-align: right; float: none; }
				}
				&.investor {
					margin-bottom: 150px;
					h6.title { margin-bottom: 25px; }
					h6 {  }
					h3 { line-height: 40px; }
					.wrapper-2 { position: relative; padding-top: 150px;
						.side-left { padding: 0px 30px 0px 0px; }
						.side-right { padding: 0px 0px 0px 30px; }
						&::after { content:""; clear: both; display: block; }
					}
					.side-right {
						.day-datetime {
							.left {
								width: 35%;
							}
							.right {
								width: 65%;
								p.date { margin: 0px; }
								p.time { margin-left: 20px; }
							}
							p { margin: 0px; }
						}
						.col-rate-container {
							h6 { font-size: 14px; }
							.col-rate { margin-bottom: 0px; }
						}
						h3 { margin-bottom: 20px; font-size: 30px;}
					}

				}
				&.csr {
					min-height: 560px; margin-bottom: 150px;
					p { font-size: 18px; line-height: 26px; }
					img.plant { max-width: 390px; position: absolute; left: -90px; }
					img.path-1 { bottom: 87px; }
					img.path-2 { bottom: 120px; max-width: 32%; }
				}
				&.career {
					background-size: 150%;
					.wrapper-2 {
						padding: 137px 70px 0px 70px;
					}
				}
			}
		}
		&:not(.home) {
			div.article-title {
				h1 { font-size: 36px; line-height: 36px; margin: 30px 0px 40px; 
					br { display: none; }
				}
			}
			p { font-size: 14px; line-height: 24px; }
			h3 {
				&.desc { font-size: 20px; line-height: 30px; }
			}
			h4 { font-size: 22px; line-height: 30px; }
			div.manajemen-list , div.article-list {
				.list {
					.desc {
						b { font-size: 16px; line-height: 24px; }
						p.title { font-size: 16px; margin-bottom: 20px;}
					}
				}
			}
			div.project { padding-top: 30px; padding-bottom: 60px; 
				h4 { font-size: 16px; line-height: 28px; }
			}
			div.recent-product-list {
    			width: 31.3%; margin-right: 3%;
    			.image { height: 120px; overflow: hidden; position: relative;
    				img { position: absolute; top: 0px; left: 0px; height: 100%; max-width: 500%; }
    			}
    			.content {
    				a.title { font-size: 14px; line-height: 22px; }
    			}
			}
			div.project-list, div.project-list-full, div.project-list-full-2 {
				height: 214px; margin-bottom: 3.5%;
				.innerText { font-size: 20px; }
			}
			div.project-list-full {
				height: 235px;
			}
			div.project-list-full-2 {
				height: 300px;
				span.play { width: 60px; height: 60px; 
					&:hover { width:54px; height: 54px; } 
				}
			}
			div.project-list-2 {
				.content {
					.inner {
						p.category { font-size: 14px; margin-bottom: 15px; }
						p.title { font-size: 24px; max-width: 70%; line-height: 28px; }
					}
				}
			}
			div.recent-product-list {
				margin-top: 15px;
			}
			div.project-1 {
				h4 { font-size: 16px; }
			}
			div.article-list {
				margin: 60px 0px 35px 0px;
				.list {
					.desc {
						padding-left: 10px;
						a.title {
						    font-size: 14px;
						    line-height: 22px;
						    margin-bottom: 18px;
						}
					}
					&:last-child { margin-bottom: 0px; }
				}
			}
			section {
				&.content {
					margin-top: 0px; padding-top: 100px; margin-bottom: 79px; top: 94px;
					aside { display: none; }
					article { margin-left: 0px; }
					ul li , ol li { font-size: 14px; line-height: 24px; margin-bottom: 12px; }
					div.wrapper-2 {
						div.content { margin-right: 0px; }
					}
					.aside-mobile { display: none; z-index: 10; top: 65px; position: absolute; width:100%; background-color: $white; @include boxSizing(border-box); border-bottom: 1px solid #f1f1f1; 
						ul {
							width: 33.3%; border-right: 1px solid #f1f1f1; padding: 27px 29px 46px; margin-bottom: 0px; position: static; @include boxSizing(border-box); background-color: $white;
							&:first-child { padding-left:0px; position: static; }
							li { 
								padding-left: 0px; position: static; cursor: pointer; position: static;
								&::before { display:none; }
								&.active {
									> a { color: $red; }
								}
								span.ctrl-dropdown { cursor: pointer; position: absolute; width: 16px; height: 21px; left: 28%; background-image: url('../img/material/arrow-slider-right.png'); background-position: bottom; background-repeat: no-repeat; background-size: 6px 10px; }
								a { font-size: 14px; color: $grey4; font-weight: 600; 
									&.active , &:hover { color: $red; }
								}
								ul {
									&.child { display: none; position: absolute; top: 0px; margin-left: 33.3%; 
										li {
											span.ctrl-dropdown { left: inherit; right: 10%; }
											ul {
												&.child { margin-left: 90%; width: 100%; border-right: 0px solid #000; 
													li { position: relative; }
												}
											}
										}
									}
								}
							}
						}
					}
				}
				&.popup {
					.popup-content {
					    top: 20%; width: 87%;
					    span.close { z-index: 10; }
					    .wrap {
					    	position: relative;
					    	.image {
					    		bottom: 0px; top: 0px; width: 45%; background-color: #000; position: absolute; overflow: hidden;
					    		img { position: absolute; height: 100%; max-width: 500%; width: auto; left: -20%; }
					    	}
					    	.desc {
					    		padding-top: 20px;
					    		b { font-size: 16px; line-height: 24px; }
					    		p.title { font-size: 16px; }
					    		&::before { left:15px; }
					    	}
					    	&::after { content: ''; clear: both; display: block; }
					    }
					    .container-arrow {
				    		div.btn-arrow { width: 40px; height: 40px; 
				    			span { width: 10px; height: 14px; background-size: 200% 100%; }
				    		}
				    	}
					}
				}
				&.banner {
					height: 340px; margin-top: 0px; position: relative; top: 94px; background-size: cover; background-position: center;
				}
			}

		}
	}
	footer {
		top: 0px; position: relative;
		.logo { margin: 0px; }
	}
}

@media screen and(min-width:1px) and(max-width:1300px){
	body {
		&.home {
			section {
				&.project {
					.slider-project-nav { 
						width: 35%; position: absolute; padding-top: 30px; bottom: 40px; right: 50px; left: auto;
						.list {
							text-align: center; height: 40px; color: transparent;
							span { position: relative; top: 30px; }
							&:not(.slick-current) {
								&::after { left:0px; right: 0px; margin: auto; top: 8px !important; }
							}
							&.slick-current {
								color : $red; font-weight : bold;
								&::after { left:0px; right: 0px; margin: auto; top: 4px !important; }
							}
						}
						&::after { position: absolute; left: -5px; right: -5px; top: 41px; height: 1px; background-color: $red; content: ''; }
						button {
	                        @include rotation(-90deg);
	                        &.slick-prev { top: 36px; left: -16px; }
	                        &.slick-next { top: 36px; right: 0px; left: inherit; right: -16px; }
	                    }
					}
				}
			}
		}
	}
}

@media screen and(min-width:768px) and(max-width:1023px){
	.hide-tablet { display: none; }
}

@media screen and(min-width:1024px) and(max-width:1280px){
	.wrapper , .wrapper-2 { width: 833px; }
	.wrapper-side { width: 100%; }
	div.download-list-container {
		.download-list {
			p {
			    line-height: 22px; font-size: 14px;
			}
			a { top: 5px; }
		}
	}
	div.search-txt-container {
		min-height: 93px;
		form {
			margin: 20px 0px;
			span.search { width: 29px; height: 29px; background-size: 100%; background-repeat: no-repeat; top: 8px; }
			input {
			    font-size: 22px;
			}
		}
	}
	body {
		p { font-size: 14px; }
		/*div.slider-3-thumb { display: none; }*/
		div.slider-3 {
			.content {
				p.title { font-size: 26px; }
			}
		}
		table {
			font-size: 14px;
		}
		div.rect-big {
			padding: 66px 0px 50px; margin-bottom: 200px;
			.content { padding: 22px 0px; padding-left: 80px; max-width: 220px; 
				span.download { top: -3px; }
			}
			h3 { font-size: 18px; line-height: 18px; margin-bottom: 45px; }
			p { font-size: 12px; line-height: 18px;  }
			br { display: none; }
			.map { margin-left: 340px; max-width: 479px; height: 340px; }
			.abs-content-2 {
				p { font-size: 14px; line-height: 24px; }
			}
			.content-right { max-width: 480px; 
				form { padding: 35px 44px; }
			}

		}
		div.rect-big-white {
		    padding: 66px 0px 50px;
		    .abs-content {
			    left: 62px; position: relative; top: 48px; transform: translateY(0px); width: 32%; float: left;
			}
		    .content-right { margin-left: 340px; max-width: 480px; }
		}
		div.box-form {
			span { font-size: 14px; }
			select , input { font-size: 14px; }
			select { padding: 19px 44px 19px 13px; }
		}
		div.box {
			select {
				z-index: 3; background-color: transparent;
				&.plain { font-size: 14px; padding: 0px 31px 0px 0px; }
			}
			input { padding: 3px 0px 8px 0px }
			&.select {
				span {
					&.arrow-select { top: 3px; z-index: 1; }
				}
			}
			span.title { font-size: 12px; }
		}
		div.downlist-container {
			.downlist {
				.content {
					a.title {
					    font-size: 14px;
					    line-height: 22px;
					}
				}
			}
		}
		div.pagination {
			ul {
				li {
					a { font-size: 13px; }
				}
			}
		}
		div.downlist2-container {
			.downlist2 {
				.title-container {
					padding: 27px 0px 44px 0px;
					a {
					    font-size: 14px;
					    line-height: 22px;
					}
				}
			}
		}
		div.list-gallery {
			.list { width: 45%; 
				p { max-width: 60%; margin-bottom: 0px; }
				b { display: inline-block; }
				&:nth-child(odd) { margin-left: 5%; }
				&:nth-child(even) { margin-right: 5%; }
			}
		}
		&.home {
			h6 { font-size: 16px; }
			h1 { font-size: 35px; line-height: 45px; }
			header {
				.bottom {
					.logo {
						img { max-height: 46px; }
					}
					.menu {
						li { padding: 21px 13px; }
					}
					.wrapper { padding:19px 0px; }
				}
				&.active {
					.bottom {
						.menu {
							li {
								> a { padding: 36px 13px; }
								&:last-child {
									 > a { padding: 36px 0px 36px 13px; }
								}
							}
						}
					}
				}
			}
			section {
				&.mainbanner {
					.inner {
						h1 { max-width: 39%; margin-bottom: 14px; }
					}
				}
				&.about {
					.image { @include boxSizing(border-box); padding: 0px 30px 0px 0px; }
					.content {
						p { margin-top: 40px; padding: 0px 0px 0px 40px; font-size: 16px; }
					}
				}
				&.project {
					width: 100%;
					.bg-content { clip-path: url("#clipPolygonTablet"); top: -40px; }
					.wrapper { width: 900px; padding-left: 70px; }
					.slider-project {
						padding-top: 50px; width: 100%;
						.list { padding-top: 30px; margin-right: 30px; padding-left: 259px;
							.title-desc {
								max-width: 239px;
								span.border { top: -20px; width: 100px; height: 6px; }
								h2 { font-size: 40px; line-height: 50px; max-width: 50%; margin: 0px 0px 40px 0px; }
							}
						}
					}
				}
				&.news {
					margin-bottom: 68px;
					.wrapper-side {
						.image {}
						.content {
							h4 { font-size: 18px; line-height: 24px; font-weight: 600; }
						}
					}
					.news-big {
						margin-bottom: 28px;
						.content {
							p.desc { font-size: 14px; line-height: 24px; height: 50px; overflow: hidden; }
						}
					}
					.news-list-container {
						.news-list {
							h4 { font-size: 14px; line-height: 22px; }
						}
					}
					.wrapper-2 { width: 86%; margin:0px 0px 0px 30px; }
				}
				&.investor {
					margin-bottom: 115px;
					.wrapper-2 { padding-top: 206px; position: relative; 
						&::after { content:''; clear: both; display: block; }
					}
					.side-right {
						padding-right: 70px;
					}
					.side-right {
						padding-left: 70px; padding-right: 0px;
						h3 {font-size: 38px;}
						.day-datetime {
								p { font-size: 14px; line-height: 24px; margin: 0px; }
							.right {
								p.date { margin-right: 0px; }
								p.time { padding-left: 20px; }
							}
						}
						.col-rate-container {
							.col-rate { margin-bottom: 5px; }
						}
					}
					h3 { font-size: 30px; }
					h6.title { margin-bottom: 26px; }
				}
				&.csr {
					margin-bottom: 85px;
					.plant { max-width: 382px; }
					img.path-2 { bottom: 90px; max-width: 268px; }
					p { font-size: 18px; }
					.wrapper-2 {
						padding-top: 229px;
						.content { max-width: 500px; position: relative; right: -70px; }
					}
				}
			}
		}
		&:not(.home) {
			b { margin-bottom: 15px; display: block; }
			div.wrapper { width: 820px; margin: 0 auto; } 
			div.article-title {
				h1 { font-size: 36px; line-height: 36px; margin: 19px 0px 34px 0px;  }
				ul.breadcrumb { margin-bottom: 0px; 
					li { padding: 0px 15px; }
				}
			}
			div.recent-product-list {
				width: 31%; margin-right: 3.5%;
				.content {
					a.title {
					    font-size: 14px;
					    line-height: 22px;
					}
				}
			}
			div.manajemen-list {
				.list {
					.desc {
						b { font-size: 16px; }
						p.title { font-size: 16px; font-style: italic; }
					}
				}
			}
			div.project-list, div.project-list-full, div.project-list-full-2 {
				height: 214px;
				.innerText { font-size: 20px; }
				span.play { width: 70px; height: 70px; 
					&:hover { width: 60px; height: 60px; }
				}
			}
			div.project-list-full { height: 235px; }
			div.project-list-2 {
				.content {
					.inner {
						p.title { font-size: 24px; max-width: 80%; line-height: 32px; }
						p { margin-bottom: 18px; }
					}
				}
			}
			div.article-list {
				.list {
					.desc {
						a.title {
						    font-size: 14px; line-height: 22px;
						}
					}
				}
			}
			h3 {
				&.desc { margin: 40px 0px; font-size: 20px; line-height: 30px; }
			}
			h4 { font-size: 20px; line-height: 22px; }
			p, b, a.title-bold { font-size: 14px; line-height: 24px; }
			header {
				.bottom {
					.menu {
						li {
							 > a { padding: 36px 13px; }
							 &:last-child {
							 	 > a { padding: 36px 0px 36px 13px; }
							 }
						}
					}
				}
			}
			section {
				&.banner {
					margin-top: 0px; height: 320px;
				}
				&.content {
					position: relative; margin-bottom: 100px;
					margin-top: 0px; padding-top: 111px;
					ul , ol {
						li { font-size: 14px; line-height: 24px; margin-bottom: 5px; }
					}
					.aside-mobile { display: none; z-index: 10; top: 65px; position: absolute; width:100%; background-color: $white; @include boxSizing(border-box); border-bottom: 1px solid #f1f1f1; 
						ul {
							width: 33.3%; border-right: 1px solid #f1f1f1; padding: 27px 29px 46px; margin-bottom: 0px; position: static; @include boxSizing(border-box); background-color: $white;
							&:first-child { padding-left:0px; position: static; }
							li { 
								padding-left: 0px; position: static; cursor: pointer; position: static;
								&::before { display:none; }
								&.active {
									> a { color: $red; }
								}
								span.ctrl-dropdown { cursor: pointer; position: absolute; width: 16px; height: 21px; left: 28%; background-image: url('../img/material/arrow-slider-right.png'); background-position: bottom; background-repeat: no-repeat; background-size: 6px 10px; }
								a { font-size: 14px; color: $grey4; font-weight: 600; 
									&.active , &:hover { color: $red; }
								}
								ul {
									&.child { display: none; position: absolute; top: 0px; margin-left: 33.3%; 
										li {
											span.ctrl-dropdown { left: inherit; right: 10%; }
											ul {
												&.child { margin-left: 90%; width: 100%; border-right: 0px solid #000; 
													li { position: relative; }
												}
											}
										}
									}
								}
							}
						}
					}
					div.wrapper , div.wrapper-2 { width: 820px; 
						aside { display: none;
							&::before { display:none; }
						}
						article { margin-left: 0px; padding: 0px 80px; 
							p { text-align: justify; }
							div.wrapper-fluid { padding: 0px 0px; @include boxSizing(border-box); }
							div.project-1 { padding: 120px 0px 35px 0px; }
						}
					}
					div.wrapper-fluid { padding: 0px 80px; @include boxSizing(border-box); }
					div.mobile-wrapper-fluid { padding-left: 0px !important; padding-right: 0px !important; @include boxSizing(border-box); 
						div.project-list-full-2 { height: 350px; }
					}
					hr { width: 81%; }
					div.project { padding: 50px 80px; }
					div.project-1 { padding: 35px 80px; }
				}
			}

		}
		div.big-text-bg {
			img { max-height: 93px; }
		}
		p.text-title { top: 60px; 
			span.line { width: 60px; }
		}
	}
	footer {
		.wrapper { width: 779px; }
	}
}